import React from 'react';
import {graphql} from 'gatsby';
import { Helmet } from 'react-helmet';
import authForms from '../components/Auth/index';
import { LangContextProvider } from '../context/LangContext'


// this minimal GraphQL query ensures that when 'gatsby develop' is running,
// any changes to content files are reflected in browser
export const query = graphql`
  query($url: String) {
    sitePage(path: {eq: $url}) {
      id
    }
  }
`;

export default class Landing extends React.Component {
    render() {

      let Content = authForms[this.props.pageContext.frontmatter.type]
      let labels = this.props.pageContext.frontmatter.labels

      return <LangContextProvider {...this.props}>
        <Helmet>
        <title>{this.props.pageContext.site.siteMetadata.title} | {this.props.pageContext.frontmatter.title}</title>
          <link href="/styles/auth.css" rel="stylesheet" />
          <link href="https://fonts.googleapis.com/css?family=Open+Sans:400,300,700,800|Reenie+Beanie:regular" rel="stylesheet" />
          <link href="https://netdna.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.css" rel="stylesheet" />
          <link rel="icon" type="image/png" href="//www.buxenger.com/images/favicon.png" />
          
        </Helmet>
        <div className={`valign-outer sign-in ${this.props.pageContext.frontmatter.type}`}>
            <div className="valign-middle flippable">
                <div className="dialog add-another-site valign-inner">
                  <Content labels={labels}></Content>
                </div>
            </div>
        </div>
        <script src="/js/auth.js"></script>
      </LangContextProvider>
    }
}
