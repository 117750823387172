import React from 'react';
import {Link} from '../../utils';

export default class RegisterForm extends React.Component {
    render() {
        return <form className="register front" data-redirect="/get-started">
                <div className="max loading" style={{ display: "none" }}></div>
                <span>{this.props.labels.title}</span>
                <div className="form">
                    <div className="row">
                        <input name="register_user" placeholder={this.props.labels.username} type="text"  />
                    </div>
                    <div className="row">
                        <input type="text" name="register_email" placeholder={this.props.labels.email} />
                    </div>
                    <div className="row">
                        <input type="password" name="register_pass" placeholder={this.props.labels.password} />
                    </div>
                </div>
                <div className="actions">
                    <div className="butt">
                        <button type="submit" className="login">
                            <i className="fa fa-chevron-right"></i>{this.props.labels.register}
                        </button>
                    </div>
                    <Link to="/login/" className="flip">
                        {this.props.labels.alreadyRegisterd}
                    </Link>
                </div>
                <div className="err"></div>
            </form>
    }
}