import React from 'react';
import {Link} from '../../utils';

export default class LoginForm extends React.Component{
    render() {
        return <form className="login front" data-redirect="https://app.buxenger.com">
                    <div className="max loading" style={{ display: "none"}}></div>
                    <span>{this.props.labels.title}</span>
                    <div className="form">
                        <div className="row">
                            <input id="UserName" name="UserName" placeholder={this.props.labels.username} type="text" />
                        </div>
                        <div className="row">
                            <input id="Password" name="Password" placeholder={this.props.labels.password} type="password" />
                        </div>
                    </div>
                    <div className="actions">
                        <div className="butt">
                            <button type="submit" className="login">
                                <i className="fa fa-chevron-right"></i>{this.props.labels.login}
                            </button>
                        </div>
                        <Link to="/signup/" className="flip">
                            {this.props.labels.notRegistered}
                        </Link> 
                        <Link to="/login/forgot/" className="flip"><br />
                            {this.props.labels.forgot}
                        </Link>
                    </div>
                    <div className="err"></div>
                </form>
    }
}