import React from 'react';
import {Link} from '../../utils';

export default class PasswordResetForm extends React.Component{
    render() {
        return <>
                <form className="forgot front" data-redirect="..">
                    <div className="max loading" style={{ display: "none"}}></div>
                    <span>{this.props.labels.title}</span>
                    <div className="form">
                        <div className="row">
                            <input id="user" name="user" placeholder={this.props.labels.username} type="text" />
                        </div>
                        <br />
                        — {this.props.labels.or} —
                        <div className="row">
                            <input type="text" name="email" placeholder={this.props.labels.email} />
                        </div>
                    </div>
                    <div className="actions">
                        <div className="butt">
                            <button type="submit" className="login">
                                <i className="fa fa-chevron-right"></i>{this.props.labels.reset}
                            </button>
                        </div>
                    </div>
                    <div className="err"></div>
                </form>
                <form className="back" >
                   <br /><br /><br /><i className="fa fa-check-circle-o fa-2x"></i><br />
                        <span className="message sent" style={{ display:'none' }}>{this.props.labels.confirmSent}</span>
                        <span className="message reset" style={{ display:'none' }}>{this.props.labels.confirmReset} <span className="new-pass"></span></span>
                    <div className="actions" style={{ display:'none' }}>
                        <div className="butt">
                            <Link to="/login/" className="login">
                                <i className="fa fa-chevron-right"></i> {this.props.labels.login}
                            </Link>
                        </div>
                    </div>
                </form>
            </>
    }
}